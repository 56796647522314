<template>
  <form @submit="$event.preventDefault()">
      <div class="price-ranges" v-if="value">
        <div class="price-range price-range-1">
          <div class="label-to">от</div>
          <input
            class="input__input"
            ref="fieldPrice1"
            type="text"
            placeholder="от"
            :value="modelNumberFrom"
            @blur="fromValue = $event.target.value"
            :disabled="loaderPrice"
          />
          <div class="b-help-item"><span class="rub">млн руб.</span></div>
        </div>
        <div class="price-range price-range-2">
          <div class="label-from">до</div>
          <input
            class="input__input"
            ref="fieldPrice2"
            type="text"
            placeholder="до"
            :value="modelNumberTo"
            @blur="toValue = $event.target.value"
            :disabled="loaderPrice"
          />
          <div class="b-help-item"><span class="rub">млн руб. <!--₽--></span></div>
        </div>
          <div class="poll-slider-container price-slider-range">
            <div class="poll-slider-line">
              <vue-slider
                v-model="value"
                :min="minRange"
                :max="maxRange"
                :minRange="1"
                :enable-cross="false"
                :tooltip="'none'"
              ></vue-slider>
              <!--<div style="transform: translateX(30px);" class="poll-slider-dot pos_a br_all z_i_2 c_p"></div>
              <div style="transform: translateX(100px);"  class="poll-slider-dot pos_a br_all z_i_2 c_p"></div>
              <div style="width:70px;left:30px" class="poll-slider-process pos_a z_i_1"></div>-->
            </div>
          </div>

      </div>


    <div v-if="error" class="alert alert-danger">{{ error }}</div>

    <div class="content__buttonBox">
      <button
        v-if="!isFirst"
        @click="$store.commit('prev')"
        class="button_def btn-prev button__type_2"
      >
        <span class="label-text">Назад</span>
        <span class="label-icon">
              <svg  viewBox="0 0 8 12"  xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99744 0.712235C7.38796 1.10276 7.38796 1.73592 6.99744 2.12645L3.12388 6L6.99744 9.87355C7.38796 10.2641 7.38796 10.8972 6.99744 11.2878C6.60691 11.6783 5.97375 11.6783 5.58322 11.2878L1.00256 6.70711C0.61204 6.31658 0.61204 5.68342 1.00256 5.29289L5.58322 0.712235C5.97375 0.321711 6.60691 0.321711 6.99744 0.712235Z" />
              </svg>
            </span>
      </button>

      <button
        :disabled="!isValid"
        @click="getResult"
        class="button_def btn-next button__type_1"
      >
        <span class="label-text">{{ isLast ? 'Показать результат' : 'Следующий вопрос' }}</span>
        <span class="label-icon">
          <svg viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.99744 0.712235C7.38796 1.10276 7.38796 1.73592 6.99744 2.12645L3.12388 6L6.99744 9.87355C7.38796 10.2641 7.38796 10.8972 6.99744 11.2878C6.60691 11.6783 5.97375 11.6783 5.58322 11.2878L1.00256 6.70711C0.61204 6.31658 0.61204 5.68342 1.00256 5.29289L5.58322 0.712235C5.97375 0.321711 6.60691 0.321711 6.99744 0.712235Z"/>
          </svg>
        </span>
      </button>
    </div>
  </form>
</template>

<script>
import vueSlider from 'vue-slider-component'
import PriceFormComponent from '@/modules/quiz/components/PriceFormComponent'

export default {
  name: 'price-form',
  mixins: [PriceFormComponent],
  components: { vueSlider },
  data(){
    return {
      realNumber: 0, // будет содержать актуальное числовое значение
      indicatorChange: false, // индикатор редактирования поля
    }
  },
  computed: {
    modelNumberFrom: {
      get() {
        let format = +this.fromValue;
        return format.toLocaleString()
      },
      set(value) {
        this.fromValue = +value.replace(/\s/g, "")
        //this.$emit('input', this.fromValue)
      },
    },
    modelNumberTo: {
      get() {
        let format2 = +this.toValue;
        return   format2.toLocaleString()
      },
      set(value) {
        this.toValue = +value.replace(/\s/g, "")
        //this.$emit('input', this.toValue)
      },
    },
  },
  methods: {

  },
}
</script>
