<template>
  <div class="qz-quiz__wrapper" :class="{'finish-step' : stat.levels == stat.level, finish : formData && !stat.level}" key="t1">
    <aside  class="qz-quiz__left">
      <!-- div class="qz-quiz__left__content">
        <div class="qz-progress">
          <div class="qz-progress__item" :style="{width: 100 / stat.levels + '%'}" v-for="(item, index) in stat.levels" :class="{active : stat.level >= index + 1}"></div>
        </div>
      </div><!--end qz-quiz__left__content -->
      
      <!-- div class="qz-question-wrapper">
        <div v-if="formData" class="qz-question">
          <div class="qz-step-counter">Вопрос {{ stat.level }} из {{ stat.levels }}</div>
          <div v-if="formData.heading" class="qz-form-title">{{ formData.heading }}</div>
        </div>
      </div -->
      <div class="qz-step-bg">
        <div class="qz-step-bg__item" v-for="(item, index) in stat.levels" :class="['bg-' + (index + 1), {'active' : stat.level == index + 1}]"></div>
        <div class="qz-step-bg__item bg-finish"></div>
      </div>
    </aside>
    <main class="qz-quiz__main">
      <div class="qz-quiz__main-header">
        <div class="qz-quiz__addr">
          <div class="ico">
            <svg viewBox="0 0 35 42" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3145 41.0361L16.3103 41.0329L16.2997 41.0245L16.2625 40.9953C16.2306 40.97 16.1845 40.9336 16.1252 40.8862C16.0069 40.7915 15.8358 40.6532 15.6196 40.4747C15.1874 40.118 14.574 39.6 13.8396 38.9472C12.3733 37.6438 10.4132 35.7932 8.44753 33.6091C6.48679 31.4304 4.48796 28.8843 2.97152 26.1885C1.46394 23.5083 0.363281 20.5571 0.363281 17.601C0.363281 13.0936 2.15387 8.77072 5.34113 5.58344C8.52841 2.39618 12.8513 0.605591 17.3587 0.605591C21.8663 0.605591 26.1891 2.39618 29.3764 5.58344C32.5635 8.77072 34.3542 13.0936 34.3542 17.601C34.3542 20.5571 33.2536 23.5083 31.7459 26.1885C30.2296 28.8843 28.2307 31.4304 26.27 33.6091C24.3043 35.7932 22.3442 37.6438 20.8778 38.9472C20.1434 39.6 19.5301 40.118 19.0979 40.4747C18.8817 40.6532 18.7106 40.7915 18.5923 40.8862C18.533 40.9336 18.4869 40.97 18.4549 40.9953L18.4177 41.0245L18.4072 41.0329L18.404 41.0352C18.404 41.0352 18.4021 41.0368 17.3587 39.6951L18.4021 41.0368C17.7884 41.514 16.9282 41.5135 16.3145 41.0361ZM17.3587 39.6951L16.3145 41.0361C16.3145 41.0361 16.3154 41.0368 17.3587 39.6951ZM22.4574 17.601C22.4574 20.417 20.1747 22.6997 17.3587 22.6997C14.5428 22.6997 12.2601 20.417 12.2601 17.601C12.2601 14.7852 14.5428 12.5024 17.3587 12.5024C20.1747 12.5024 22.4574 14.7852 22.4574 17.601Z"/>
            </svg>
          </div>
          10 минут пешком <br>до ул. Красной
        </div>
        <div v-if="stat.level" class="qz-progress__circle-wrapper">
            <div class="qz-progress__circle-text">{{ stat.level }}/{{ stat.levels }}</div>
            <svg viewBox="0 0 32 32" class="qz-progress__circle__container">
              <circle class="qz-progress__circle" r="16" cx="16" cy="16"></circle>
              <circle class="qz-progress__circle-progress" r="16" cx="16" cy="16" :stroke-dasharray="progressBar(stat.progress)" ></circle>
            </svg>
        </div>
      </div><!--end qz-quiz__main-header -->
      <div v-if="formData && stat.level" class="qz-form">
        <div class="qz-form-wrapper">
          <div v-if="formData.heading" class="qz-form-title">{{ formData.heading }}</div>
          <div class="qz-form__content">
            <template v-if="formData.fields">
              <dynamic-form/>
            </template>
            <template v-else-if="formData.component">
              <component :is="formData.component"/>
            </template>
          </div>
        </div>
      </div><!--end qz-form -->
      <component v-else-if="formData && !stat.level" :is="formData.component"/>
      <div class="timer-search">
        <div class="timer-search-text">
          Один момент. Ищем для вас лучшие квартиры ...
        </div>
      </div>
    </main>
  </div>
  <!-- div v-else-if="formData" class="listing__layoutAll growOn pRel" key="t2">
    <component :is="formData.component"/>
  </div -->
</template>

<script>
  import Quiz from '../quiz/App'
  import DynamicForm from '@/modules/quiz-landing/components/DynamicFormComponent'
  import PriceForm from '@/modules/quiz-landing/components/PriceFormComponent'
  import PriceFormRadio from '@/modules/quiz-landing/components/PriceFormRadioComponent'
  import SendForm from '@/modules/quiz-landing/components/SendFormComponent'
  import FinishingFormComponent from '@/modules/quiz-landing/components/FinishingFormComponent'

  export default {
    mixins: [Quiz],
    components: {
      'dynamic-form': DynamicForm,
      'price-form': PriceForm,
      'price-form-radio': PriceFormRadio,
      'send-form': SendForm,
      'finishing-form': FinishingFormComponent
    },
    methods: {
      progressBar (val) {
        return val + ' 100';
      },
    }
  }
</script>
