<template>
    <fragment>
      <SendFormModal v-show="isShowModal" v-model="isShowModal">
        <div slot="heading" v-html="renderModalHeading" class="modal__title"/>
        <div slot="content">
          <form v-if="!success" novalidate @submit.prevent="checkForm" class="modal__form-box">
            <div class="modal__form-container">
        
              <div class="modal__form-wrapper" :class="{ 'has-error': errors.has('username') }">
                <input
                    type="text"
                    v-model="username"
                    name="username"
                    v-validate="'min:2|max:50'"
                    class="modal__form text_md"
                    placeholder="Как вас зовут?"
                />
                <div v-if="errors.has('username')" class="modal__form-error">
                  Имя должно содержать от 2 до 50 символов
                </div>
              </div>
        
              <div class="modal__form-wrapper" :class="{ 'has-error': errors.has('phone') }">
                <input
                    v-model="phone"
                    name="phone"
                    type="text"
                    v-phone
                    maxlength="18"
                    v-validate="{ required: true, regex: phoneRegex }"
                    class="modal__form"
                    placeholder="Номер телефона*"
                    autocomplete="off"
                    @keyup="eventLastLetter"
                />
                <div v-if="errors.has('phone')" class="modal__form-error">
                  Необходимо заполнить поле «Телефон» в формате +7 (xxx) xxx-xx-xx
                </div>
              </div>
            </div>
      
            <div v-if="error" class="alert alert-danger" v-html="error"></div>
      
            <button
                :disabled="loader"
                type="submit"
                class="button_def button__type_1"
            >
              Получить подборку
            </button>
          </form>
        </div>
      </SendFormModal>
      <div class="qz-result-flats" :class="{show : isShowResult}">
        <div class="qz-result-flats__header">
          {{ renderResultHeading }}
        </div>
        <div class="qz-result-flats__list">
          <div class="qz-result-flats__item" v-for="item in result.novos">
            <a :href="item.alias" class="qz-result-flats__item-wrapper">
              <span class="img"><span class="img-wrapper"><img :src="item.image" alt=""></span></span>
              <span class="title">{{ item.name }}</span>
            </a><!--end qz-result-flats__wrapper -->
          </div><!--end qz-result-flats__item -->
        </div><!--end qz-result-flats__list -->
      </div><!--end qz-result-flats -->
      
      <div v-if="0" class="qz-finish-result">
        <div class="qz-finish__item-wrapper col-1">
          <div class="qz-finish__b-title">
            <div class="num">{{ result.counts.flats }}</div>
            <div class="text">новостроек по вашему <br>запросу</div>
          </div>
        </div>
        <div class="qz-finish__item-wrapper col-2">
          <div class="qz-finish__item qz-finish__item--square-bg">
            <div class="img" style="background-image: url(/images/quiz/item-bg-1.png)"></div>
            <div class="bottom-item">
              <div class="title">ЖК «Серебряный фонтан»</div>
              <div class="addr"><div class="addr-circ" style="background: #D38A46;"></div> Профсоюзная</div>
            </div><!--end bottom-item -->
          </div>
        </div>
        <div class="qz-finish__item-wrapper col-2">
          <div class="qz-finish__item qz-finish__item--square-bg">
            <div class="img" style="background-image: url(/images/quiz/item-bg-2.png)"></div>
            <div class="bottom-item">
              <div class="title">ЖК «Серебряный фонтан»</div>
              <div class="addr"><div class="addr-circ" style="background: #D38A46;"></div> Профсоюзная</div>
            </div><!--end bottom-item -->
          </div>
        </div>
        <div class="qz-finish__item-wrapper col-4">
          <div class="qz-finish__item qz-finish__item--square-img">
            <div class="img" style="background-image: url(/images/quiz/item-bg-3.png)"></div>
            <div class="bottom-item">
              <div class="title">ЖК «Серебряный фонтан»</div>
              <div class="addr"><div class="addr-circ" style="background: #D38A46;"></div> Профсоюзная</div>
            </div><!--end bottom-item -->
          </div>
        </div>
        <div class="qz-finish__item-wrapper col-4">
          <div class="qz-finish__item qz-finish__item--square-img">
            <div class="img" style="background-image: url(/images/quiz/item-bg-4.png)"></div>
            <div class="bottom-item">
              <div class="title">ЖК «Серебряный фонтан»</div>
              <div class="addr"><div class="addr-circ" style="background: #D38A46;"></div> Профсоюзная</div>
            </div><!--end bottom-item -->
          </div>
        </div>
        <div class="qz-finish__item-wrapper col-2">
          <div class="qz-finish__item qz-finish__item--square-img2">
            <div class="img" style="background-image: url(/images/quiz/item-bg-5.png)"></div>
            <div class="bottom-item">
              <div class="title">ЖК «Серебряный фонтан»</div>
              <div class="addr"><div class="addr-circ" style="background: #D38A46;"></div> Профсоюзная</div>
            </div><!--end bottom-item -->
          </div>
        </div>
      </div><!--end qz-finish-result -->
      
        <!-- div class="pRel heightAll listing__layoutPart">
            <div class="listing__complete pAbs">
                <img width="24" src="/images/quiz/icon-complete.svg" alt="">
            </div>
            <div class="flex-wrapper flexWrap">
                <div class="text_slg bold listing__title" v-html="renderHeading"/>
                <button v-if="success" class="button_def button__size button__type_1 button__again text_md medium"
                        @click="goHome()">Начать заново
                </button>
            </div>

            <div class="content__wrapper heightAll">
                <div class="content__form">
                    <div class="content__formWrapper">
                        <list-result :class="{'listing--blur2' : !success }" class="listing--blur" />
                        
                    </div>
                </div>
            </div -->
          
            <!--<BuildList :list="result.novos" />-->

            <!-- div class="listing__gradient"></div>
        </div -->

    </fragment>
</template>
<style scoped>
.modal__formBox {
  max-width: 660px;
  margin: 0 auto;
}
</style>
<script>
  import {TextHelper} from '@/helpers/TextHelper'
  import SendFormComponent from '@/modules/quiz/components/SendFormComponent'
  import SendFormModal from '@/modules/quiz-landing/components/SendFormModal'
  import BuildList from '@/modules/quiz-landing/components/BuildListComponent'
  import ListResult from '@/modules/quiz-landing/components/ListResult'
  import {Fragment} from 'vue-fragment'
  export default {
    name: 'send-form',
    components: {SendFormModal, BuildList, Fragment, ListResult},
    mixins: [SendFormComponent],
    data: () => ({
      isShowModal: false,
      isShowResult: false,
      flats: 15,
    }),
    mounted() {
      this.flats = this.getRandomInt(4, 20);
      // modal will be showing in 2 sec
      setTimeout(() => {
        document.body.classList.add('show-result');
      }, 2500);
      setTimeout(() => this.isShowModal = true, 4500);

//    var id = document.querySelector('[ya-counter]');
//
//    if (id) {
//      window['yaCounter' + id.getAttribute('ya-counter')].reachGoal('quiz_request');
//    }
    },
    methods: {
      triggerEvent() {
      },
      getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
      },
    },
    computed: {
      renderHeading() {
        // const build = TextHelper.declensionWords(this.result.counts.builds, ['новостройка', 'новостройки', 'новостроек']);
        const build = TextHelper.declensionWords(this.result.counts.flats, ['квартира', 'квартиры', 'квартир']);
      },
      renderModalHeading() {
        if (this.success) {
          // не закрываем после отправки
          // this.isShowModal = false;
        }
        
        // let flats = this.result.counts.flats > 15 ? this.result.counts.flats : 20;
        // const build = TextHelper.declensionWords(this.result.counts.builds, ['новостройка', 'новостройки', 'новостроек']);
        const build = TextHelper.declensionWords(this.flats, ['квартира', 'квартиры', 'квартир']);
        // const find = TextHelper.declensionWords(this.result.counts.builds, ['найдена', 'найдено', 'найдено']);
        const find = TextHelper.declensionWords(this.flats, ['подобрана', 'подобрано', 'подобрано']);
        const text = `Для вас ${find} ${this.flats} ${build}. `;
    
        return !this.success
            ? text + `Оставьте свои контакты, чтобы получить подборку`
            : 'Спасибо, вы получите результаты совсем скоро! У вас есть возможность изменить параметры и сделать новый подбор.'
      },
      renderResultHeading() {
        // let flats = this.result.counts.flats > 15 ? this.result.counts.flats : 20;
        // const build = TextHelper.declensionWords(this.result.counts.builds, ['новостройка', 'новостройки', 'новостроек']);
        const build = TextHelper.declensionWords(this.flats, ['квартира', 'квартиры', 'квартир']);
        // const find = TextHelper.declensionWords(this.result.counts.builds, ['найдена', 'найдено', 'найдено']);
        const find = TextHelper.declensionWords(this.flats, ['подобрана', 'подобрано', 'подобрано']);
        return `Для вас ${find} ${this.flats} ${build}. `;
      },
    },
  }
</script>
